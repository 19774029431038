import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlideshowComponent } from './slideshow.component';
import { BrowserTransferStateModule } from '@angular/platform-browser';
import { PointerService } from './pointer.service';
export class SlideshowModule { static decorators: DecoratorInvocation[] = [
{ type: NgModule, args: [{
  imports: [
    CommonModule,
    BrowserTransferStateModule
  ],
  declarations: [
    SlideshowComponent
  ],
  exports: [
    SlideshowComponent
  ]
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
];
}

function SlideshowModule_tsickle_Closure_declarations() {
/** @type {?} */
SlideshowModule.decorators;
/**
 * @nocollapse
 * @type {?}
 */
SlideshowModule.ctorParameters;
}


interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
