import { Component, ElementRef, EventEmitter, Inject, Input, Output, PLATFORM_ID, Renderer2, ViewChild, DoCheck, NgZone, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';
// import { SwipeService } from './swipe.service';

import { isPlatformServer, DOCUMENT } from '@angular/common';
import { ISlide } from './ISlide';
import { IImage } from './IImage';
import { DomSanitizer, TransferState, makeStateKey, SafeStyle } from '@angular/platform-browser';
import { PointerService } from './pointer.service';
import { Subscription } from 'rxjs';

const /** @type {?} */ FIRST_SLIDE_KEY = makeStateKey<any>('firstSlide');
export class SlideshowComponent implements OnInit, AfterViewInit, DoCheck, OnChanges, OnDestroy {
  slideIndex: number = -1;
  slides: ISlide[] = [];
  hideLeftArrow: boolean = false;
  hideRightArrow: boolean = false;
private _urlCache: (string | IImage)[];
private _autoplayIntervalId: any;
private _initial: boolean = true;
private _isHidden: boolean = false;
private _slideSub: Subscription;
private _clickSub: Subscription;

   imageUrls: (string | IImage)[] = [];
   height: string = '100%';
   minHeight: string;
   arrowSize: string;
   showArrows: boolean = true;
   disableSwiping: boolean = false;
   autoPlay: boolean = false;
   autoPlayInterval: number = 3333;
   stopAutoPlayOnSlide: boolean = true;
   autoPlayWaitForLazyLoad: boolean = true;
   debug: boolean;
   backgroundSize: string = 'cover';
   backgroundPosition: string = 'center center';
   backgroundRepeat: string = 'no-repeat';
   showDots: boolean = false;
   dotColor: string = '#FFF';
   showCaptions: boolean = true;
   captionColor: string = '#FFF';
   captionBackground: string = 'rgba(0, 0, 0, .35)';
   lazyLoad: boolean = false;
   hideOnNoSlides: boolean = false;
   fullscreen: boolean = false;
   enableZoom: boolean = false;
   enablePan: boolean = false;
   noLoop: boolean = false;

   onSlideLeft = new EventEmitter<number>();
   onSlideRight = new EventEmitter<number>();
   onSwipeLeft = new EventEmitter<number>();
   onSwipeRight = new EventEmitter<number>();
   onFullscreenExit = new EventEmitter<boolean>();
   onIndexChanged = new EventEmitter<number>();
   onImageLazyLoad = new EventEmitter<ISlide>();
   onClick = new EventEmitter<{ slide: ISlide, index: number }>();

   container: ElementRef;
   prevArrow: ElementRef;
   nextArrow: ElementRef;
/**
 * @return {?}
 */
get safeStyleDotColor(): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(`--dot-color: ${ this.dotColor }`);
  }
/**
 * @param {?} _pointerService
 * @param {?} _renderer
 * @param {?} _transferState
 * @param {?} _ngZone
 * @param {?} _cdRef
 * @param {?} sanitizer
 * @param {?} platform_id
 * @param {?} document
 */
constructor(
private _pointerService: PointerService,
private _renderer: Renderer2,
private _transferState: TransferState,
private _ngZone: NgZone,
private _cdRef: ChangeDetectorRef,
public sanitizer: DomSanitizer,
private platform_id: any,
private document: any
  ) { }
/**
 * @return {?}
 */
ngOnInit() {
    if (this.debug !== undefined) {
      console.warn('[Deprecation Warning]: The debug input will be removed from ng-simple-slideshow in 1.3.0');
    }
    this._slideSub = this._pointerService.slideEvent.subscribe((indexDirection: number) => {
      this.onSlide(indexDirection, true);
    });
    this._clickSub = this._pointerService.clickEvent.subscribe(() => {
      this._onClick();
    });
    if (this.noLoop) {
      this.hideLeftArrow = true;
    }
  }
/**
 * @return {?}
 */
ngAfterViewInit(): void {
    this._pointerService.bind(this.container);
  }
/**
 * @return {?}
 */
ngOnDestroy() {
    try {
      if (this._slideSub && !this._slideSub.closed) {
        this._slideSub.unsubscribe();
      }
    }
    catch ( /** @type {?} */error) {
      console.warn('Slide Subscription error caught in ng-simple-slideshow OnDestroy:', error);
    }

    try {
      if (this._clickSub && !this._clickSub.closed) {
        this._clickSub.unsubscribe();
      }
    }
    catch ( /** @type {?} */error) {
      console.warn('Click Subscription error caught in ng-simple-slideshow OnDestroy:', error);
    }

    try {
      this._pointerService.unbind(this.container);
    }
    catch ( /** @type {?} */error) {
      console.warn('Pointer Service unbind error caught in ng-simple-slideshow OnDestroy:', error);
    }

    try {
      if (this._autoplayIntervalId) {
        this._ngZone.runOutsideAngular(() => clearInterval(this._autoplayIntervalId));
        this._autoplayIntervalId = null;
      }
    }
    catch ( /** @type {?} */error) {
      console.warn('Autoplay cancel error caught in ng-simple-slideshow OnDestroy:', error);
    }
  }
/**
 * @param {?} changes
 * @return {?}
 */
ngOnChanges(changes: SimpleChanges) {
    if (changes['noLoop']) {
      if (changes['noLoop'].currentValue) {
        this.hideLeftArrow = this.slideIndex <= 0;
        this.hideRightArrow = this.slideIndex === this.slides.length - 1;
      }
      else {
        this.hideLeftArrow = false;
        this.hideRightArrow = false;
      }

      this._cdRef.detectChanges();
    }
  }
/**
 * @return {?}
 */
ngDoCheck() {
    // if this is the first being called, create a copy of the input
    if (this.imageUrls && this.imageUrls.length > 0) {
      if (this._initial === true) {
        this._urlCache = Array.from(this.imageUrls);
      }

      if (this._isHidden === true) {
        this._renderer.removeStyle(this.container.nativeElement, 'display');
        this._isHidden = false;
      }

      this.setSlides();
    }
    else if (this.hideOnNoSlides === true) {
      this._renderer.setStyle(this.container.nativeElement, 'display', 'none');
      this._isHidden = true;
    }

    this.setStyles();
    this.handleAutoPlay();
    this._pointerService.disableSwiping = this.disableSwiping;
    this._pointerService.enableZoom = this.enableZoom;
    this._pointerService.enablePan = this.enablePan;
  }
/**
 * \@description this is the function that should be called to make the slides change.
 *              indexDirection to move back is -1, to move forward is 1, and to stay in place is 0.
 *              0 is taken into account for failed swipes
 * @param {?} indexDirection
 * @param {?=} isSwipe
 * @return {?}
 */
onSlide(indexDirection: number, isSwipe?: boolean): void {
    this.handleAutoPlay(this.stopAutoPlayOnSlide);
    this.slide(indexDirection, isSwipe);
  }
/**
 * \@description Redirect to current slide "href" if defined
 * @return {?}
 */
private _onClick(): void {
    const /** @type {?} */ currentSlide = this.slides.length > 0 && this.slides[this.slideIndex];
    this.onClick.emit({ slide: currentSlide, index: this.slideIndex });

    if (currentSlide && currentSlide.image.clickAction) {
      currentSlide.image.clickAction();
    }
    else if (currentSlide && currentSlide.image.href) {
      this.document.location.href = currentSlide.image.href;
    }
  }
/**
 * \@description set the index to the desired index - 1 and simulate a right slide
 * @param {?} index
 * @return {?}
 */
goToSlide(index: number) {
    const /** @type {?} */ beforeClickIndex = this.slideIndex;
    this.slideIndex = index - 1;
    this.setSlideIndex(1);

    if (this.slides[this.slideIndex] && !this.slides[this.slideIndex].loaded) {
      this.loadRemainingSlides();
    }

    this.handleAutoPlay(this.stopAutoPlayOnSlide);
    this.slideRight(beforeClickIndex);
    this.slides[beforeClickIndex].selected = false;
    this.slides[this.slideIndex].selected = true;

    this._cdRef.detectChanges();
  }
/**
 * \@description set the index to the desired index - 1 and simulate a right slide
 * @param {?} index
 * @return {?}
 */
getSlideStyle(index: number) {
    const /** @type {?} */ slide = this.slides[index];

    if (slide && slide.loaded) {
      return {
        "background-image": 'url(' + slide.image.url + ')',
        "background-size": slide.image.backgroundSize || this.backgroundSize,
        "background-position": slide.image.backgroundPosition || this.backgroundPosition,
        "background-repeat": slide.image.backgroundRepeat || this.backgroundRepeat
      };
    }
    else {
      // doesn't compile correctly if returning an empty object, sooooo.....
      return {
        "background-image": undefined,
        "background-size": undefined,
        "background-position": undefined,
        "background-repeat": undefined
      };
    }
  }
/**
 * @param {?} e
 * @return {?}
 */
exitFullScreen(e: Event) {
    e.preventDefault();
    this.fullscreen = false;
    this.onFullscreenExit.emit(true);
  }
/**
 * \@description Set the new slide index, then make the transition happen.
 * @param {?} indexDirection
 * @param {?=} isSwipe
 * @return {?}
 */
private slide(indexDirection: number, isSwipe?: boolean): void {
    const /** @type {?} */ oldIndex = this.slideIndex;

    if (this.setSlideIndex(indexDirection)) {
      if (this.slides[this.slideIndex] && !this.slides[this.slideIndex].loaded) {
        this.loadRemainingSlides();
      }

      if (indexDirection === 1) {
        this.slideRight(oldIndex, isSwipe);
      }
      else {
        this.slideLeft(oldIndex, isSwipe);
      }

      this.slides[oldIndex].selected = false;
      this.slides[this.slideIndex].selected = true;
    }

    this._cdRef.detectChanges();
  }
/**
 * \@description This is just treating the url array like a circular list.
 * @param {?} indexDirection
 * @return {?}
 */
private setSlideIndex(indexDirection: number): boolean {
    let /** @type {?} */ willChange = true;
    this.slideIndex += indexDirection;

    if (this.noLoop) {
      this.hideRightArrow = this.slideIndex === this.slides.length - 1;
      this.hideLeftArrow = false;
    }

    if (this.slideIndex < 0) {
      if (this.noLoop) {
        this.slideIndex -= indexDirection;
        willChange = false;
        this.hideLeftArrow = true;
      }
      else {
        this.slideIndex = this.slides.length - 1;
      }
    }
    else if (this.slideIndex >= this.slides.length) {
      if (this.noLoop) {
        this.slideIndex -= indexDirection;
        willChange = false;
        this.hideRightArrow = true;
      }
      else {
        this.slideIndex = 0;
      }
    }

    if (willChange) {
      this.onIndexChanged.emit(this.slideIndex);
    }

    return willChange;
  }
/**
 * \@description This function handles the variables to move the CSS classes around accordingly.
 *              In order to correctly handle animations, the new slide as well as the slides to
 *              the left and right are assigned classes.
 * @param {?} oldIndex
 * @param {?=} isSwipe
 * @return {?}
 */
private slideLeft(oldIndex: number, isSwipe?: boolean): void {
    if (isSwipe === true) {
      this.onSwipeLeft.emit(this.slideIndex);
    }
    else {
      this.onSlideLeft.emit(this.slideIndex);
    }

    this.slides[this.getLeftSideIndex(oldIndex)].leftSide = false;
    this.slides[oldIndex].leftSide = true;
    this.slides[oldIndex].action = 'slideOutLeft';
    this.slides[this.slideIndex].rightSide = false;
    this.slides[this.getRightSideIndex()].rightSide = true;
    this.slides[this.slideIndex].action = 'slideInRight';
  }
/**
 * \@description This function handles the variables to move the CSS classes around accordingly.
 *              In order to correctly handle animations, the new slide as well as the slides to
 *              the left and right are assigned classes.
 * @param {?} oldIndex
 * @param {?=} isSwipe
 * @return {?}
 */
private slideRight(oldIndex: number, isSwipe?: boolean): void {
    if (isSwipe === true) {
      this.onSwipeRight.emit(this.slideIndex);
    }
    else {
      this.onSlideRight.emit(this.slideIndex);
    }

    this.slides[this.getRightSideIndex(oldIndex)].rightSide = false;
    this.slides[oldIndex].rightSide = true;
    this.slides[oldIndex].action = 'slideOutRight';
    this.slides[this.slideIndex].leftSide = false;
    this.slides[this.getLeftSideIndex()].leftSide = true;
    this.slides[this.slideIndex].action = 'slideInLeft';
  }
/**
 * \@description Check to make sure slide images have been set or haven't changed
 * @return {?}
 */
private setSlides(): void {
    if (this.imageUrls) {
      if (this.checkCache() || this._initial === true) {
        this._initial = false;
        this._urlCache = Array.from(this.imageUrls);
        this.slides = [];

        if (this.lazyLoad === true) {
          this.buildLazyLoadSlideArray();
        }
        else {
          this.buildSlideArray();
        }
        this._cdRef.detectChanges();
      }
    }
  }
/**
 * \@description create the slides without background urls, which will be added in
 *              for the "lazy load," then load only the first slide
 * @return {?}
 */
private buildLazyLoadSlideArray(): void {
    for (let /** @type {?} */ image of this.imageUrls) {
      this.slides.push({
        image: (typeof image === 'string' ? { url: null } : { url: null, href: image.href || '' }),
        action: '',
        leftSide: false,
        rightSide: false,
        selected: false,
        loaded: false
      });
    }
    if (this.slideIndex === -1) {
      this.slideIndex = 0;
    }
    this.slides[this.slideIndex].selected = true;
    this.loadFirstSlide();
    this.onIndexChanged.emit(this.slideIndex);
  }
/**
 * \@description create the slides with background urls all at once
 * @return {?}
 */
private buildSlideArray(): void {
    for (let /** @type {?} */ image of this.imageUrls) {
      this.slides.push({
        image: (typeof image === 'string' ? { url: image } : image),
        action: '',
        leftSide: false,
        rightSide: false,
        selected: false,
        loaded: true
      });
    }
    if (this.slideIndex === -1) {
      this.slideIndex = 0;
    }
    this.slides[this.slideIndex].selected = true;
    this.onIndexChanged.emit(this.slideIndex);
  }
/**
 * \@description load the first slide image if lazy loading
 *              this takes server side and browser side into account
 * @return {?}
 */
private loadFirstSlide(): void {
    const /** @type {?} */ tmpIndex = this.slideIndex;
    const /** @type {?} */ tmpImage = this.imageUrls[tmpIndex];

    // if server side, we don't need to worry about the rest of the slides
    if (isPlatformServer(this.platform_id)) {
      this.slides[tmpIndex].image = (typeof tmpImage === 'string' ? { url: tmpImage } : tmpImage);
      this.slides[tmpIndex].loaded = true;
      this._transferState.set(FIRST_SLIDE_KEY, this.slides[tmpIndex]);
    }
    else {
      const /** @type {?} */ firstSlideFromTransferState = this._transferState.get(FIRST_SLIDE_KEY, /** @type {?} */(( null as any)));
      // if the first slide didn't finish loading on the server side, we need to load it
      if (firstSlideFromTransferState === null) {
        let /** @type {?} */ loadImage = new Image();
        loadImage.src = (typeof tmpImage === 'string' ? tmpImage : tmpImage.url);
        loadImage.addEventListener('load', () => {
          this.slides[tmpIndex].image = (typeof tmpImage === 'string' ? { url: tmpImage } : tmpImage);
          this.slides[tmpIndex].loaded = true;
          this.onImageLazyLoad.emit(this.slides[tmpIndex]);
          this._cdRef.detectChanges();
        });
      }
      else {
        this.slides[tmpIndex] = firstSlideFromTransferState;
        this._transferState.remove(FIRST_SLIDE_KEY);
      }
    }
  }
/**
 * \@description if lazy loading in browser, start loading remaining slides
 * \@todo: figure out how to not show the spinner if images are loading fast enough
 * @return {?}
 */
private loadRemainingSlides(): void {
    for (let /** @type {?} */ i = 0; i < this.slides.length; i++) {
      if (!this.slides[i].loaded) {
        new Promise((resolve) => {
          const /** @type {?} */ tmpImage = this.imageUrls[i];
          let /** @type {?} */ loadImage = new Image();
          loadImage.addEventListener('load', () => {
            this.slides[i].image = (typeof tmpImage === 'string' ? { url: tmpImage } : tmpImage);
            this.slides[i].loaded = true;
            this._cdRef.detectChanges();
            this.onImageLazyLoad.emit(this.slides[i]);
            resolve();
          });
          loadImage.src = (typeof tmpImage === 'string' ? tmpImage : tmpImage.url);
        });
      }
    }
  }
/**
 * \@description Start or stop autoPlay, don't do it at all server side
 * @param {?=} stopAutoPlay
 * @return {?}
 */
private handleAutoPlay(stopAutoPlay?: boolean): void {
    if (isPlatformServer(this.platform_id)) {
      return;
    }

    if (stopAutoPlay === true || this.autoPlay === false) {
      if (this._autoplayIntervalId) {
        this._ngZone.runOutsideAngular(() => clearInterval(this._autoplayIntervalId));
        this._autoplayIntervalId = null;
      }
    }
    else if (!this._autoplayIntervalId) {
      this._ngZone.runOutsideAngular(() => {
        this._autoplayIntervalId = setInterval(() => {
          if (!this.autoPlayWaitForLazyLoad || (this.autoPlayWaitForLazyLoad && this.slides[this.slideIndex] && this.slides[this.slideIndex].loaded)) {
            this._ngZone.run(() => this.slide(1));
          }
        }, this.autoPlayInterval);
      });
    }
  }
/**
 * \@description Keep the styles up to date with the input
 * @return {?}
 */
private setStyles(): void {
    if (this.fullscreen) {
      this._renderer.setStyle(this.container.nativeElement, 'height', '100%');
      // Would be nice to make it configurable
      this._renderer.setStyle(this.container.nativeElement, 'background-color', 'white');
    }
    else {
      // Would be nice to make it configurable
      this._renderer.removeStyle(this.container.nativeElement, 'background-color');
      if (this.height) {
        this._renderer.setStyle(this.container.nativeElement, 'height', this.height);
      }

      if (this.minHeight) {
        this._renderer.setStyle(this.container.nativeElement, 'min-height', this.minHeight);
      }
    }
    if (this.arrowSize) {
      this._renderer.setStyle(this.prevArrow.nativeElement, 'height', this.arrowSize);
      this._renderer.setStyle(this.prevArrow.nativeElement, 'width', this.arrowSize);
      this._renderer.setStyle(this.nextArrow.nativeElement, 'height', this.arrowSize);
      this._renderer.setStyle(this.nextArrow.nativeElement, 'width', this.arrowSize);
    }
  }
/**
 * \@description compare image array to the cache, returns false if no changes
 * @return {?}
 */
private checkCache(): boolean {
    return !(this._urlCache.length === this.imageUrls.length && this._urlCache.every((cacheElement, i) => cacheElement === this.imageUrls[i]));
  }
/**
 * \@description get the index for the slide to the left of the new slide
 * @param {?=} i
 * @return {?}
 */
private getLeftSideIndex(i?: number): number {
    if (i === undefined) {
      i = this.slideIndex;
    }

    if (--i < 0) {
      i = this.slides.length - 1;
    }

    return i;
  }
/**
 * \@description get the index for the slide to the right of the new slide
 * @param {?=} i
 * @return {?}
 */
private getRightSideIndex(i?: number): number {
    if (i === undefined) {
      i = this.slideIndex;
    }

    if (++i >= this.slides.length) {
      i = 0;
    }

    return i;
  }
/**
 * \@description a trackBy function for the ngFor loops
 * @param {?} index
 * @param {?} slide
 * @return {?}
 */
trackByFn(index: number, slide: ISlide): any {
    return slide.image;
  }
/**
 * \@description don't let click events fire, handle in pointer service instead
 * @param {?} event
 * @return {?}
 */
handleClick(event) {
    event.preventDefault();
  }
static decorators: DecoratorInvocation[] = [
{ type: Component, args: [{
  selector: 'slideshow',
  template: `
    <!-- fullscreen bar -->
    <div [class.display-none]="!fullscreen"
         class="fs-container"
         (click)="exitFullScreen($event)">
      <i title="Back"
         class="arrow-exitfs prev"></i>
    </div>
    <div #container
         class="slideshow-container"
         [class.slideshow-container-fs]="fullscreen">
      <!-- slides -->
      <a *ngFor="let slide of slides; index as i; trackBy: trackByFn"
         class="slides"
         href="#!"
         tabindex="-1"
         title="{{slide?.image?.title}}"
         [ngStyle]="getSlideStyle(i)"
         (click)="handleClick($event)"
         [class.selected]="slide?.selected"
         [class.hide-slide]="!slide?.selected && !slide?.leftSide && !slide?.rightSide"
         [class.left-side]="slide?.leftSide"
         [class.right-side]="slide?.rightSide"
         [class.slide-in-left]="slide?.action === 'slideInLeft'"
         [class.slide-in-right]="slide?.action === 'slideInRight'"
         [class.slide-out-left]="slide?.action === 'slideOutLeft'"
         [class.slide-out-right]="slide?.action === 'slideOutRight'"
         [class.link]="slide?.image?.clickAction || slide?.image?.href">
        <div class="loader"
             *ngIf="!slide?.loaded"></div>
        <div *ngIf="showCaptions && slide?.image?.caption"
             class="caption"
             [ngStyle]="{
               'color': captionColor,
               'background-color': captionBackground
              }"
             [innerHTML]="slide?.image?.caption">
        </div>
      </a>
      <!-- left arrow -->
      <div [class.display-none]="!showArrows || hideLeftArrow"
           (click)="onSlide(-1)"
           class="arrow-container prev">
        <i #prevArrow
           title="Previous"
           class="arrow prev"></i>
      </div>
      <!-- right arrow -->
      <div [class.display-none]="!showArrows || hideRightArrow"
           (click)="onSlide(1)"
           class="arrow-container next">
        <i #nextArrow
           title="Next"
           class="arrow next"></i>
      </div>
      <!-- dots -->
      <ul class="slick-dots"
          *ngIf="showDots">
        <li *ngFor="let slide of slides; index as i; trackBy: trackByFn"
            (click)="goToSlide(i)"
            [class.slick-active]="slide.selected">
          <button type="button"
                  [attr.style]="safeStyleDotColor">
            {{i}}
          </button>
        </li>
      </ul>
    </div>
  `,
  styles: [`
    /*
     styles adapted from https://www.w3schools.com/w3css/4/w3.css
     arrow styles adapted from https://codepen.io/minustalent/pen/Frhaw
     */
    .display-none {
      display: none !important; }

    .fs-container {
      display: block;
      cursor: pointer;
      position: fixed;
      z-index: 1;
      top: 16px;
      left: 16px;
      width: 46px;
      height: 46px;
      text-align: center;
      padding: 0;
      background-color: rgba(0, 0, 0, 0.2);
      -webkit-transition: all .2s ease-in-out;
      transition: all .2s ease-in-out; }
      .fs-container:hover {
        background-color: rgba(0, 0, 0, 0.33); }
      .fs-container .arrow-exitfs {
        display: block;
        width: 30px;
        height: 30px;
        background: transparent;
        border-top: 2px solid #f2f2f2;
        -webkit-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out; }
        .fs-container .arrow-exitfs.prev {
          -webkit-transform: rotate(-45deg);
                  transform: rotate(-45deg);
          position: relative;
          left: 18px;
          top: 18px; }
        .fs-container .arrow-exitfs:after {
          content: '';
          width: 30px;
          height: 30px;
          background: transparent;
          border-top: 2px solid #f2f2f2;
          -webkit-transform: rotate(90deg);
                  transform: rotate(90deg);
          position: absolute;
          left: -15px;
          top: -17px; }

    .slideshow-container.slideshow-container-fs {
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%; }

    .slideshow-container {
      position: relative;
      display: block;
      margin: auto;
      height: 100%;
      width: 100%;
      overflow: hidden; }
      .slideshow-container .hide-slide {
        visibility: hidden;
        position: absolute;
        top: -100vw;
        left: -100vw;
        opacity: 0; }
      .slideshow-container .slides {
        -ms-touch-action: pan-y;
            touch-action: pan-y;
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        visibility: visible;
        opacity: 1;
        display: block; }
        .slideshow-container .slides.selected {
          left: 0; }
        .slideshow-container .slides.left-slide {
          left: -100%; }
        .slideshow-container .slides.right-slide {
          left: 100%; }
        .slideshow-container .slides.slide-in-left {
          left: 0;
          -webkit-animation: slideInLeft 0.5s cubic-bezier(0.42, 0, 0.58, 1);
                  animation: slideInLeft 0.5s cubic-bezier(0.42, 0, 0.58, 1); }
        .slideshow-container .slides.slide-in-right {
          left: 0;
          -webkit-animation: slideInRight 0.5s cubic-bezier(0.42, 0, 0.58, 1);
                  animation: slideInRight 0.5s cubic-bezier(0.42, 0, 0.58, 1); }
        .slideshow-container .slides.slide-out-left {
          left: -100%;
          -webkit-animation: slideOutLeft 0.5s cubic-bezier(0.42, 0, 0.58, 1);
                  animation: slideOutLeft 0.5s cubic-bezier(0.42, 0, 0.58, 1); }
        .slideshow-container .slides.slide-out-right {
          left: 100%;
          -webkit-animation: slideOutRight 0.5s cubic-bezier(0.42, 0, 0.58, 1);
                  animation: slideOutRight 0.5s cubic-bezier(0.42, 0, 0.58, 1); }
        .slideshow-container .slides.link {
          cursor: pointer; }
        .slideshow-container .slides:not(.link) {
          cursor: default; }
      .slideshow-container .caption {
        position: absolute;
        bottom: 0;
        padding: 10px;
        width: 100%; }
      .slideshow-container .arrow-container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        position: absolute;
        top: 0;
        height: 100%;
        width: auto;
        cursor: pointer;
        background-size: 100%;
        background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(transparent));
        background-image: linear-gradient(transparent, transparent);
        z-index: 100;
        -webkit-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none; }
        .slideshow-container .arrow-container:before {
          display: block;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
          width: 100%;
          z-index: -100;
          -webkit-transition: opacity 0.45s;
          transition: opacity 0.45s; }
        .slideshow-container .arrow-container.prev {
          left: 0; }
          .slideshow-container .arrow-container.prev:before {
            background-image: -webkit-gradient(linear, right top, left top, from(transparent), to(rgba(0, 0, 0, 0.75)));
            background-image: linear-gradient(to left, transparent, rgba(0, 0, 0, 0.75));
            content: ''; }
        .slideshow-container .arrow-container.next {
          right: 0; }
          .slideshow-container .arrow-container.next:before {
            background-image: -webkit-gradient(linear, left top, right top, from(transparent), to(rgba(0, 0, 0, 0.75)));
            background-image: linear-gradient(to right, transparent, rgba(0, 0, 0, 0.75));
            content: ''; }
        .slideshow-container .arrow-container .arrow {
          display: block;
          margin: auto;
          width: 30px;
          height: 30px;
          background: transparent;
          border-top: 2px solid #f2f2f2;
          border-left: 2px solid #f2f2f2;
          -webkit-transition: all .2s ease-in-out;
          transition: all .2s ease-in-out;
          -webkit-user-select: none;
             -moz-user-select: none;
              -ms-user-select: none;
                  user-select: none; }
          .slideshow-container .arrow-container .arrow:before {
            display: block;
            height: 200%;
            width: 200%;
            margin-left: -50%;
            margin-top: -50%;
            content: "";
            -webkit-transform: rotate(45deg);
                    transform: rotate(45deg); }
          .slideshow-container .arrow-container .arrow.prev {
            -webkit-transform: rotate(-45deg);
                    transform: rotate(-45deg);
            position: relative;
            left: 20px;
            margin-right: 10px; }
          .slideshow-container .arrow-container .arrow.next {
            -webkit-transform: rotate(135deg);
                    transform: rotate(135deg);
            position: relative;
            right: 20px;
            margin-left: 10px; }
      .slideshow-container .slick-dots {
        display: block;
        bottom: 15px;
        z-index: 1;
        text-align: center;
        position: absolute;
        padding: 0;
        left: 0;
        right: 0;
        margin: 0 auto; }
        .slideshow-container .slick-dots li {
          display: inline;
          margin: 0;
          padding: 0; }
          .slideshow-container .slick-dots li button {
            border: none;
            background: none;
            text-indent: -9999px;
            font-size: 0;
            width: 20px;
            height: 20px;
            outline: none;
            position: relative;
            z-index: 1;
            cursor: pointer; }
            .slideshow-container .slick-dots li button:before {
              content: '';
              width: 4px;
              height: 4px;
              background: var(--dot-color, #FFF);
              border-radius: 4px;
              display: block;
              position: absolute;
              top: 50%;
              left: 50%;
              -webkit-transform: translate(-50%, -50%);
                      transform: translate(-50%, -50%);
              opacity: .7;
              -webkit-transition: all .5s ease-out;
              transition: all .5s ease-out; }
          .slideshow-container .slick-dots li.slick-active button:before {
            -webkit-transform: translate(-50%, -50%) scale(1.4);
                    transform: translate(-50%, -50%) scale(1.4);
            opacity: 1; }

    @media screen and (min-width: 768px) {
      .slideshow-container .arrow-container:hover:before {
        opacity: 1; }
      .slideshow-container .arrow-container:hover .arrow {
        border-width: 4px; }
      .slideshow-container .arrow-container .arrow:hover {
        border-width: 4px; } }

    @-webkit-keyframes slideInRight {
      0% {
        left: -100%; }
      100% {
        left: 0; } }

    @keyframes slideInRight {
      0% {
        left: -100%; }
      100% {
        left: 0; } }

    @-webkit-keyframes slideInLeft {
      0% {
        left: 100%; }
      100% {
        left: 0; } }

    @keyframes slideInLeft {
      0% {
        left: 100%; }
      100% {
        left: 0; } }

    @-webkit-keyframes slideOutRight {
      0% {
        left: 0; }
      100% {
        left: -100%; } }

    @keyframes slideOutRight {
      0% {
        left: 0; }
      100% {
        left: -100%; } }

    @-webkit-keyframes slideOutLeft {
      0% {
        left: 0; }
      100% {
        left: 100%; } }

    @keyframes slideOutLeft {
      0% {
        left: 0; }
      100% {
        left: 100%; } }

    .loader {
      position: absolute;
      left: 50%;
      margin-left: -20px;
      top: 50%;
      margin-top: -20px;
      border: 5px solid #f3f3f3;
      border-top: 5px solid #555;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      -webkit-animation: spin 1s linear infinite;
              animation: spin 1s linear infinite; }

    @-webkit-keyframes spin {
      0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg); }
      100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg); } }

    @keyframes spin {
      0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg); }
      100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg); } }
  `],
  providers: [PointerService],
  changeDetection: ChangeDetectionStrategy.OnPush
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
{type: PointerService, },
{type: Renderer2, },
{type: TransferState, },
{type: NgZone, },
{type: ChangeDetectorRef, },
{type: DomSanitizer, },
{type: undefined, decorators: [{ type: Inject, args: [PLATFORM_ID, ] }, ]},
{type: undefined, decorators: [{ type: Inject, args: [DOCUMENT, ] }, ]},
];
static propDecorators: {[key: string]: DecoratorInvocation[]} = {
'imageUrls': [{ type: Input },],
'height': [{ type: Input },],
'minHeight': [{ type: Input },],
'arrowSize': [{ type: Input },],
'showArrows': [{ type: Input },],
'disableSwiping': [{ type: Input },],
'autoPlay': [{ type: Input },],
'autoPlayInterval': [{ type: Input },],
'stopAutoPlayOnSlide': [{ type: Input },],
'autoPlayWaitForLazyLoad': [{ type: Input },],
'debug': [{ type: Input },],
'backgroundSize': [{ type: Input },],
'backgroundPosition': [{ type: Input },],
'backgroundRepeat': [{ type: Input },],
'showDots': [{ type: Input },],
'dotColor': [{ type: Input },],
'showCaptions': [{ type: Input },],
'captionColor': [{ type: Input },],
'captionBackground': [{ type: Input },],
'lazyLoad': [{ type: Input },],
'hideOnNoSlides': [{ type: Input },],
'fullscreen': [{ type: Input },],
'enableZoom': [{ type: Input },],
'enablePan': [{ type: Input },],
'noLoop': [{ type: Input },],
'onSlideLeft': [{ type: Output },],
'onSlideRight': [{ type: Output },],
'onSwipeLeft': [{ type: Output },],
'onSwipeRight': [{ type: Output },],
'onFullscreenExit': [{ type: Output },],
'onIndexChanged': [{ type: Output },],
'onImageLazyLoad': [{ type: Output },],
'onClick': [{ type: Output },],
'container': [{ type: ViewChild, args: ['container', ] },],
'prevArrow': [{ type: ViewChild, args: ['prevArrow', ] },],
'nextArrow': [{ type: ViewChild, args: ['nextArrow', ] },],
};
}

function SlideshowComponent_tsickle_Closure_declarations() {
/** @type {?} */
SlideshowComponent.decorators;
/**
 * @nocollapse
 * @type {?}
 */
SlideshowComponent.ctorParameters;
/** @type {?} */
SlideshowComponent.propDecorators;
/** @type {?} */
SlideshowComponent.prototype.slideIndex;
/** @type {?} */
SlideshowComponent.prototype.slides;
/** @type {?} */
SlideshowComponent.prototype.hideLeftArrow;
/** @type {?} */
SlideshowComponent.prototype.hideRightArrow;
/** @type {?} */
SlideshowComponent.prototype._urlCache;
/** @type {?} */
SlideshowComponent.prototype._autoplayIntervalId;
/** @type {?} */
SlideshowComponent.prototype._initial;
/** @type {?} */
SlideshowComponent.prototype._isHidden;
/** @type {?} */
SlideshowComponent.prototype._slideSub;
/** @type {?} */
SlideshowComponent.prototype._clickSub;
/** @type {?} */
SlideshowComponent.prototype.imageUrls;
/** @type {?} */
SlideshowComponent.prototype.height;
/** @type {?} */
SlideshowComponent.prototype.minHeight;
/** @type {?} */
SlideshowComponent.prototype.arrowSize;
/** @type {?} */
SlideshowComponent.prototype.showArrows;
/** @type {?} */
SlideshowComponent.prototype.disableSwiping;
/** @type {?} */
SlideshowComponent.prototype.autoPlay;
/** @type {?} */
SlideshowComponent.prototype.autoPlayInterval;
/** @type {?} */
SlideshowComponent.prototype.stopAutoPlayOnSlide;
/** @type {?} */
SlideshowComponent.prototype.autoPlayWaitForLazyLoad;
/** @type {?} */
SlideshowComponent.prototype.debug;
/** @type {?} */
SlideshowComponent.prototype.backgroundSize;
/** @type {?} */
SlideshowComponent.prototype.backgroundPosition;
/** @type {?} */
SlideshowComponent.prototype.backgroundRepeat;
/** @type {?} */
SlideshowComponent.prototype.showDots;
/** @type {?} */
SlideshowComponent.prototype.dotColor;
/** @type {?} */
SlideshowComponent.prototype.showCaptions;
/** @type {?} */
SlideshowComponent.prototype.captionColor;
/** @type {?} */
SlideshowComponent.prototype.captionBackground;
/** @type {?} */
SlideshowComponent.prototype.lazyLoad;
/** @type {?} */
SlideshowComponent.prototype.hideOnNoSlides;
/** @type {?} */
SlideshowComponent.prototype.fullscreen;
/** @type {?} */
SlideshowComponent.prototype.enableZoom;
/** @type {?} */
SlideshowComponent.prototype.enablePan;
/** @type {?} */
SlideshowComponent.prototype.noLoop;
/** @type {?} */
SlideshowComponent.prototype.onSlideLeft;
/** @type {?} */
SlideshowComponent.prototype.onSlideRight;
/** @type {?} */
SlideshowComponent.prototype.onSwipeLeft;
/** @type {?} */
SlideshowComponent.prototype.onSwipeRight;
/** @type {?} */
SlideshowComponent.prototype.onFullscreenExit;
/** @type {?} */
SlideshowComponent.prototype.onIndexChanged;
/** @type {?} */
SlideshowComponent.prototype.onImageLazyLoad;
/** @type {?} */
SlideshowComponent.prototype.onClick;
/** @type {?} */
SlideshowComponent.prototype.container;
/** @type {?} */
SlideshowComponent.prototype.prevArrow;
/** @type {?} */
SlideshowComponent.prototype.nextArrow;
/** @type {?} */
SlideshowComponent.prototype._pointerService;
/** @type {?} */
SlideshowComponent.prototype._renderer;
/** @type {?} */
SlideshowComponent.prototype._transferState;
/** @type {?} */
SlideshowComponent.prototype._ngZone;
/** @type {?} */
SlideshowComponent.prototype._cdRef;
/** @type {?} */
SlideshowComponent.prototype.sanitizer;
/** @type {?} */
SlideshowComponent.prototype.platform_id;
/** @type {?} */
SlideshowComponent.prototype.document;
}


interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
